import Spinner from "@/components/Spinner";
import useLists from "@/lists/hooks/useLists";
import { PlusIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import { useState } from "react";
import { z } from "zod";

const listSchema = z.object({
  title: z.string().min(1, "Title is required").max(100, "Title is too long"),
});

const CreateListForm = ({ entry, onCreateList }) => {
  const [title, setTitle] = useState("");
  const [error, setError] = useState("");
  const [isCreating, setIsCreating] = useState(false);

  const { createList } = useLists();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setIsCreating(true);

    const result = listSchema.safeParse({ title });
    if (result.success) {
      try {
        const newList = await createList({
          title: result.data.title,
          elementId: entry ? entry.id : undefined,
        });
        setTitle("");
        onCreateList(newList);
      } catch (err) {
        setError("An error occurred while creating the list");
      }
    } else {
      setError(result.error.errors[0].message);
    }

    setIsCreating(false);
  };

  const isSubmitDisabled = !title || !!error || isCreating;

  return (
    <form
      onSubmit={handleSubmit}
      className={clsx(
        "flex h-12 w-full min-w-52 items-center space-x-4 font-body text-sm"
      )}
    >
      <input
        type="text"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        placeholder={`Add to a new list`}
        className={clsx("flex-1 border-none bg-transparent px-4 outline-none", {
          "border-red-500": error,
        })}
        title={error}
        disabled={isCreating}
      />
      <button
        type="submit"
        className={clsx(
          "absolute right-0 flex size-12 items-center justify-center",
          "bg-gradient-to-r from-transparent via-zinc-900 to-zinc-900",
          { "cursor-not-allowed opacity-50": isSubmitDisabled }
        )}
        disabled={isSubmitDisabled}
      >
        {isCreating ? (
          <Spinner size="sm" />
        ) : (
          <PlusIcon
            className={clsx("size-4.5", { "text-gray-500": isSubmitDisabled })}
            aria-label="Create list"
          />
        )}
      </button>
    </form>
  );
};

export default CreateListForm;
